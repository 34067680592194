// extracted by mini-css-extract-plugin
export var base = "CommunityModule-module--base--308OD";
export var rightAlignedContainer = "CommunityModule-module--rightAlignedContainer--2q-fk";
export var rightAlignedContainerSmall = "CommunityModule-module--rightAlignedContainerSmall--27GAm";
export var leftAlignedContainer = "CommunityModule-module--leftAlignedContainer--3om9d";
export var blogContainer = "CommunityModule-module--blogContainer--1LgIC CommunityModule-module--base--308OD";
export var container = "CommunityModule-module--container--XyypV CommunityModule-module--base--308OD";
export var largeContainer = "CommunityModule-module--largeContainer--mYYsr CommunityModule-module--base--308OD";
export var mobileSmallPadding = "CommunityModule-module--mobileSmallPadding--2fRFX";
export var fullWidthMobile = "CommunityModule-module--fullWidthMobile--1rXqz";
export var gridWithSidebar = "CommunityModule-module--gridWithSidebar--2vyEn";
export var noMaxWidth = "CommunityModule-module--noMaxWidth--1Wjli";
export var community = "CommunityModule-module--community--3NeWP CommunityModule-module--rightAlignedContainer--2q-fk CommunityModule-module--mobileSmallPadding--2fRFX";
export var marginContainer = "CommunityModule-module--marginContainer--3Hqv0";