// extracted by mini-css-extract-plugin
export var base = "MarketingVideoTutorialPage-module--base--3ykgz";
export var rightAlignedContainer = "MarketingVideoTutorialPage-module--rightAlignedContainer--3hLoO";
export var rightAlignedContainerSmall = "MarketingVideoTutorialPage-module--rightAlignedContainerSmall--1u7kP";
export var leftAlignedContainer = "MarketingVideoTutorialPage-module--leftAlignedContainer--27bn_";
export var blogContainer = "MarketingVideoTutorialPage-module--blogContainer--3X76z MarketingVideoTutorialPage-module--base--3ykgz";
export var container = "MarketingVideoTutorialPage-module--container--2tqxL MarketingVideoTutorialPage-module--base--3ykgz";
export var largeContainer = "MarketingVideoTutorialPage-module--largeContainer--3ptK9 MarketingVideoTutorialPage-module--base--3ykgz";
export var mobileSmallPadding = "MarketingVideoTutorialPage-module--mobileSmallPadding--1QjIf";
export var fullWidthMobile = "MarketingVideoTutorialPage-module--fullWidthMobile--1rTrC";
export var gridWithSidebar = "MarketingVideoTutorialPage-module--gridWithSidebar--1UycL";
export var noMaxWidth = "MarketingVideoTutorialPage-module--noMaxWidth--3kcIK";
export var hero = "MarketingVideoTutorialPage-module--hero--3e_iA";
export var videoPlayer = "MarketingVideoTutorialPage-module--videoPlayer--3678V";
export var copyLockupList = "MarketingVideoTutorialPage-module--copyLockupList--WWIB4";
export var featuredTemplates = "MarketingVideoTutorialPage-module--featuredTemplates--37-U1";
export var pitch = "MarketingVideoTutorialPage-module--pitch--1kViC";
export var community = "MarketingVideoTutorialPage-module--community--2e-Am";