export default function setHeroVideoSchema(hero) {
  const {
    createdAt,
    videoSchemaContentUrl,
    videoSchemaDescription,
    videoSchemaName,
    videoSchemaThumbnail,
    videoSchemaTranscript
  } = hero;

  if (videoSchemaContentUrl || videoSchemaDescription || videoSchemaName || videoSchemaThumbnail || videoSchemaTranscript) {
    return {
      contentUrl   : videoSchemaContentUrl,
      description  : videoSchemaDescription && videoSchemaDescription.videoSchemaDescription,
      name         : videoSchemaName,
      thumbnailUrl : videoSchemaThumbnail && videoSchemaThumbnail.file.url,
      transcript   : videoSchemaTranscript && videoSchemaTranscript.videoSchemaTranscript,
      uploadDate   : createdAt
    };
  }
  return null;
}
