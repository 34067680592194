import setHeroVideoSchemaInfo from './setHeroVideoSchema';

export default function getSeoInfoForMarketingPage(page, description, canonicalUrlPage, imageFieldName, hero) {
  const seoInfo = {
    title               : page.title,
    description         : description && description.description,
    slug                : page.slug,
    canonicalSlug       : canonicalUrlPage && canonicalUrlPage.slug,
    noIndex             : page.noIndex,
    image               : (imageFieldName && imageFieldName.file && imageFieldName.file.url) ? imageFieldName.file.url : null,
    heroVideoSchemaInfo : hero ? setHeroVideoSchemaInfo(hero) : null
  };
  return seoInfo;
}
