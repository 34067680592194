import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import EmbedVideoPlayer from '../EmbedVideoPlayer';
import { marginContainer, wrap } from './VideoPlayerModule.module.css';

export default function VideoPlayerModule({
  className,
  data
}) {
  const {
    hasMarginBottom,
    youtubeVideoLink
  } = data;
  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });
  return (
    <div className={classes}>
      <EmbedVideoPlayer
        url={youtubeVideoLink}
      />
    </div>
  );
}

export const VideoPlayerDataPropsObject = {
  hasMarginBottom  : PropTypes.bool,
  youtubeVideoLink : PropTypes.string.isRequired
};

VideoPlayerModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(VideoPlayerDataPropsObject).isRequired
};

VideoPlayerModule.defaultProps = {
  className : null
};

export const VideoPlayerModuleData = graphql`
  fragment VideoPlayerModuleData on ContentfulComponentVideoPlayer {
    hasMarginBottom
    youtubeVideoLink
  }
`;
