import { Community } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ModuleMarkdown from '../ModuleMarkdown';
import { community, marginContainer } from './CommunityModule.module.css';

export default function CommunityModule({
  className,
  data
}) {
  const {
    body,
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    desktopImage,
    hasMarginBottom,
    mobileImage,
    tagline,
    title
  } = data;

  const classes = classNames(className, community, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <Community
      className={classes}
      ctaCopy={ctaCopy}
      ctaTrackingClass={ctaTrackingClass}
      ctaUrl={ctaUrl}
      desktopImageSrcFluid={desktopImage.fluid}
      mobileImageSrcFluid={mobileImage.fluid}
      tagline={tagline}
      title={title}
    >
      <ModuleMarkdown>{body.childMarkdownRemark.htmlAst}</ModuleMarkdown>
    </Community>
  );
}

export const CommunityDataPropsObject = {
  body : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }).isRequired,
  ctaCopy          : PropTypes.string,
  ctaTrackingClass : PropTypes.string,
  ctaUrl           : PropTypes.string,
  desktopImage     : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number.isRequired,
      sizes       : PropTypes.string.isRequired,
      src         : PropTypes.string.isRequired,
      srcSet      : PropTypes.string.isRequired,
      srcSetWebp  : PropTypes.string.isRequired,
      srcWebp     : PropTypes.string.isRequired
    })
  }).isRequired,
  hasMarginBottom : PropTypes.bool,
  mobileImage     : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number.isRequired,
      sizes       : PropTypes.string.isRequired,
      src         : PropTypes.string.isRequired,
      srcSet      : PropTypes.string.isRequired,
      srcSetWebp  : PropTypes.string.isRequired,
      srcWebp     : PropTypes.string.isRequired
    })
  }).isRequired,
  tagline : PropTypes.string,
  title   : PropTypes.string.isRequired
};

CommunityModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(CommunityDataPropsObject).isRequired
};

CommunityModule.defaultProps = {
  className : null
};

export const CommunityModuleData = graphql`
  fragment CommunityModuleData on ContentfulComponentCommunity {
    body {
      childMarkdownRemark {
        htmlAst
      }
    }
    ctaCopy
    ctaTrackingClass
    ctaUrl
    desktopImage {
      fluid(quality: 80, maxWidth: 806, maxHeight: 512) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    hasMarginBottom
    mobileImage {
      fluid(maxWidth: 671, maxHeight: 199) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    tagline
    title
  }
`;
