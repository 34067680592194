import '../../utils/marketingPageDataFragment';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CommunityModule, { CommunityDataPropsObject } from '../../components/CommunityModule';
import CopyLockupListModule, { CopyLockupListDataPropsObject } from '../../components/CopyLockupListModule';
import FeaturedTemplatesModule, { FeaturedTemplatesDataPropsObject } from '../../components/FeaturedTemplatesModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import VideoPlayerModule, { VideoPlayerDataPropsObject } from '../../components/VideoPlayerModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import getSeoInfo from '../../utils/getSeoInfoForMarketingPage';
import {
  community as communityClass,
  copyLockupList as copyLockupListClass,
  featuredTemplates as featuredTemplatesClass,
  hero as heroClass,
  pitch as pitchClass,
  videoPlayer as videoPlayerClass
} from './MarketingVideoTutorialPage.module.css';

export default function MarketingVideoTutorialPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;

  const {
    community,
    copyLockupList,
    featuredTemplates,
    hero,
    pitch,
    videoPlayer
  } = componentsQueryResultToData(components);

  const SEOInfo = getSeoInfo(page, description, canonicalUrlPage, imageFieldName, hero);

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={heroClass} data={hero} />
      <VideoPlayerModule className={videoPlayerClass} data={videoPlayer} />
      <CopyLockupListModule className={copyLockupListClass} data={copyLockupList} />
      <FeaturedTemplatesModule className={featuredTemplatesClass} data={featuredTemplates} />
      <PitchModule className={pitchClass} data={pitch} />
      <CommunityModule className={communityClass} data={community} />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingVideoTutorialPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingVideoTutorialPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCommunity']),
          ...CommunityDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyLockupList']),
          ...CopyLockupListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFeaturedTemplates']),
          ...FeaturedTemplatesDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentVideoPlayer']),
          ...VideoPlayerDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
