import { Pitch, useClient } from '@animoto/components';
import Bowser from 'bowser';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { marginContainer, wrap } from './PitchModule.module.css';

export default function PitchModule({
  blobs,
  className,
  data
}) {
  const {
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    downloadAppStoreCtaUrl,
    downloadAppStoreTrackingClass,
    forceShowAppStoreBadge,
    hasMarginBottom,
    hideBlobs,
    hideBottomWave,
    hideTopWave,
    showCreditCardCopy,
    price,
    priceCopy,
    priceDuration,
    secondaryCtaCopy,
    secondaryCtaTrackingClass,
    secondaryCtaUrl,
    showAppStoreBadge,
    title
  } = data;

  // if price reflects old personal pricing, update to pro pricing
  const displayPrice = price === '$5' ? '$33' : price;
  const iosUser = useClient() && Bowser.parse(window.navigator.userAgent).os.name.includes('iOS');
  const showBadgeOnIosDevice = (iosUser && showAppStoreBadge) || forceShowAppStoreBadge;
  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      {blobs}
      <Pitch
        className={className}
        ctaCopy={ctaCopy}
        ctaTrackingClass={ctaTrackingClass}
        ctaUrl={ctaUrl}
        downloadAppStoreCtaUrl={downloadAppStoreCtaUrl}
        downloadAppStoreTrackingClass={downloadAppStoreTrackingClass}
        hideBlobs={hideBlobs}
        hideBottomWave={hideBottomWave}
        hideTopWave={hideTopWave}
        isIos={showBadgeOnIosDevice}
        price={displayPrice}
        priceCopy={priceCopy}
        priceDuration={priceDuration}
        secondaryCTACopy={secondaryCtaCopy}
        secondaryCTATrackingClass={secondaryCtaTrackingClass}
        secondaryCTAUrl={secondaryCtaUrl}
        showNoCreditCardCopy={showCreditCardCopy}
        title={title}
      />
    </div>
  );
}

export const PitchDataPropsObject = {
  ctaCopy                       : PropTypes.string,
  ctaTrackingClass              : PropTypes.string,
  ctaUrl                        : PropTypes.string,
  downloadAppStoreCtaUrl        : PropTypes.string,
  downloadAppStoreTrackingClass : PropTypes.string,
  forceShowAppStoreBadge        : PropTypes.bool,
  hasMarginBottom               : PropTypes.bool,
  hideBlobs                     : PropTypes.bool,
  hideBottomWave                : PropTypes.bool,
  hideTopWave                   : PropTypes.bool,
  price                         : PropTypes.string,
  priceCopy                     : PropTypes.string,
  priceDuration                 : PropTypes.string,
  secondaryCtaCopy              : PropTypes.string,
  secondaryCtaTrackingClass     : PropTypes.string,
  secondaryCtaUrl               : PropTypes.string,
  showAppStoreBadge             : PropTypes.bool,
  showCreditCardCopy            : PropTypes.bool,
  title                         : PropTypes.string
};

PitchModule.propTypes = {
  blobs     : PropTypes.node,
  className : PropTypes.string,
  data      : PropTypes.shape(PitchDataPropsObject).isRequired
};

PitchModule.defaultProps = {
  blobs     : null,
  className : null
};

export const PitchModuleData = graphql`
  fragment PitchModuleData on ContentfulComponentPitch {
    ctaCopy
    ctaTrackingClass
    ctaUrl
    downloadAppStoreCtaUrl
    downloadAppStoreTrackingClass
    forceShowAppStoreBadge
    hasMarginBottom
    hideBlobs
    hideBottomWave
    hideTopWave
    showCreditCardCopy
    price
    priceCopy
    priceDuration
    secondaryCtaCopy
    secondaryCtaTrackingClass
    secondaryCtaUrl
    showAppStoreBadge
    title
   }
`;
